import { graphql, useStaticQuery } from 'gatsby'

export default function useNavFeatures() {
  const { contentfulFeatureCollection } = useStaticQuery(graphql`
    query NavFeaturesQuery {
      contentfulFeatureCollection(
        contentfulid: { eq: "Navigation Collection" }
      ) {
        ...featureCollection
      }
    }
  `)

  return {
    data: contentfulFeatureCollection,
  }
}
