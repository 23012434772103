import * as MdIcons from 'react-icons/md'
import { appendTrailingSlashIfNoTrailingSlash } from '~/helpers/stringHelpers'
import {
  Box,
  Button,
  Card,
  Divider,
  Flex,
  Icon,
  IconButton,
  SimpleGrid,
  Text,
} from '@hometime-ui/core'
import { css } from '@emotion/core'
import { extractHtmlFromMarkdownField } from '~/helpers/dataHelpers'
import { MARKETING_CONTACT_US_LINK } from '~/helpers/constants'
import Logo from '@hometime-ui/logo'
import MobileNavDrawer from './MobileNavDrawer'
import React, { useEffect, useRef, useState } from 'react'
import useNavFeatures from '~/hooks/useNavFeatures'
import useScreen from '~/hooks/useScreen'
import WidthWrapper from './WidthWrapper'

export const NAVLINKS = [
  {
    text: 'Features',
    href: '#',
  },
  {
    text: 'Pricing',
    href: '/pricing',
  },
  {
    text: 'About us',
    href:
      '/blog/how-we-built-a-market-leading-airbnb-management-company-and-software-to',
  },
  {
    text: 'Blog',
    href: '/blog',
  },
  {
    text: 'Login',
    href: 'https://auth.app.hometime.io/',
  },
]

export default function Header({ children }) {
  const { isMobile, isDesktop, helpers } = useScreen()
  const [isNavOpen, setIsNavOpen] = useState(false)
  const headerEl = useRef(null)

  const { data } = useNavFeatures()

  const { showDrawerNav, showDesktopNav } = helpers

  useEffect(() => {
    window.addEventListener('scroll', checkIfHeaderShouldBeFixed)

    return () => {
      window.removeEventListener('scroll', checkIfHeaderShouldBeFixed)
    }
  }, [])

  const checkIfHeaderShouldBeFixed = () => {
    const offset = isDesktop ? 72 : 60
    const shouldFixHeader = window.scrollY >= offset
    const shouldUnfixHeader = window.scrollY == 0

    if (shouldFixHeader) {
      headerEl.current.classList.add('fixed')
    }

    if (shouldUnfixHeader) {
      headerEl.current.classList.remove('fixed')
    }
  }

  const headerCss = css`
    position: relative;
    z-index: 2;
    background: white;

    &.fixed {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      animation: slide 0.2s ease-out;
    }

    @keyframes slide {
      0% {
        transform: translateY(-100%);
      }
      100% {
        transform: translateY(0);
      }
    }
  `

  return (
    <Box
      as="header"
      zIndex="5"
      ref={headerEl}
      css={headerCss}
      boxShadow="inset 0px -1px 0px #E2E4E7"
      mb={isDesktop ? '-72px' : '-60px'}
    >
      <WidthWrapper pos="relative">
        <Flex justifyContent="space-between" alignItems="stretch">
          <Text as="a" py={showDrawerNav ? 5 : 6} d="inline-block" href="/">
            <Logo
              color="teal.200"
              h={isMobile ? 5 : 6}
              w={isMobile ? 25 : '132px'}
            />
          </Text>
          {renderNavLinks()}
        </Flex>
      </WidthWrapper>
      <Divider />
    </Box>
  )

  function renderNavLinks() {
    return (
      <Flex alignItems={{ base: 'center', lg: 'stretch' }}>
        {showDrawerNav ? (
          <MobileNavDrawer
            isOpen={isNavOpen}
            onClose={() => setIsNavOpen(false)}
          />
        ) : (
          showDesktopNav && renderDesktopNav()
        )}
        <Button
          as="a"
          href={MARKETING_CONTACT_US_LINK}
          bg="teal.300"
          color="white"
          fontWeight="700"
          px="4"
          py={{ base: 2, lg: 3 }}
          alignSelf="center"
          h="auto"
        >
          Try for free
        </Button>
        {showDrawerNav && (
          <IconButton
            ml="5"
            size="sm"
            fontSize="24px"
            icon={<Icon as={MdIcons.MdMenu} w="8" h="8" />}
            border="none"
            bg="transparent"
            color="teal.300"
            variant="outline"
            onClick={() => setIsNavOpen(true)}
          />
        )}
      </Flex>
    )
  }

  function renderDesktopNav() {
    return (
      <Flex alignItems="stretch">
        {NAVLINKS.map((navLink) => (
          <Flex key={navLink.text} role="group" alignItems="center">
            <Text
              as="a"
              target={navLink.openOnNewTab ? '_blank' : '_self'}
              href={navLink.href}
              color="gray.800"
              fontWeight="700"
              mr={{ md: 4, lg: 8 }}
              py="6"
              px="2"
              _hover={{
                color: 'teal.300',
              }}
              onClick={
                navLink.text === 'Features'
                  ? (e) => e.preventDefault()
                  : undefined
              }
            >
              {navLink.text}
            </Text>
            {navLink.text === 'Features' && renderFeaturesSubmenu()}
          </Flex>
        ))}
      </Flex>
    )
  }

  function renderFeaturesSubmenu() {
    return (
      <Box
        zIndex="2"
        pos="absolute"
        top="100%"
        left="0"
        right="0"
        maxWidth="960px"
        mx="auto"
        transition="max-height .5s, opacity .1s"
        overflow="hidden"
        opacity="0"
        maxH="0"
        // negative margin so it wont disappear right away
        mt={-3}
        _groupHover={{ maxH: '2000px', opacity: 1, p: '8', pt: '6' }}
      >
        <Card w="full" p="6">
          <SimpleGrid columns={{ md: 2, lg: 3 }} spacingX="6" spacingY="1">
            {data.items.map(({ name, icon, shortDescription, slug }) => {
              return (
                <Flex
                  as="a"
                  href={`/${appendTrailingSlashIfNoTrailingSlash(slug)}`}
                  key={name}
                  alignItems="flex-start"
                  borderRadius="md"
                  p="4"
                  transition="all .15s"
                  _hover={{ bg: 'gray.200' }}
                  role="group"
                >
                  <Flex
                    borderRadius="full"
                    w="12"
                    h="12"
                    bg="teal.100"
                    alignItems="center"
                    justifyContent="center"
                    mr="2"
                  >
                    <Icon as={MdIcons[icon]} color="teal.200" w="5" h="5" />
                  </Flex>
                  <Box flex="1">
                    <Text
                      color="gray.800"
                      fontSize="sm"
                      fontWeight="700"
                      mb="1"
                    >
                      {name}
                    </Text>
                    <Box
                      fontSize="xs"
                      color="gray.700"
                      dangerouslySetInnerHTML={{
                        __html: extractHtmlFromMarkdownField(shortDescription),
                      }}
                    />
                  </Box>
                </Flex>
              )
            })}
          </SimpleGrid>
        </Card>
      </Box>
    )
  }
}
