import { useMediaQuery } from '@chakra-ui/core'

// sm: "480px",
// md: "768px",
// lg: "992px",
// xl: "1280px",
// "2xl": "1536px",

export default function useScreen() {
  const [isMobile] = useMediaQuery(`(max-width: 480px)`)
  const [isTablet] = useMediaQuery(`(min-width: 481px) and (max-width: 992px)`)
  const [isLargeTablet] = useMediaQuery(
    `(min-width: 769px) and (max-width: 992px)`
  )
  const [isDesktop] = useMediaQuery('(min-width: 993px)')
  const [isLargeDesktop] = useMediaQuery('(min-width: 1281px)')

  const helpers = {
    showDrawerNav: (isMobile || isTablet) && !isLargeTablet,
    showDesktopNav: isDesktop || isLargeTablet,
  }

  return {
    isMobile,
    isTablet,
    isLargeTablet,
    isDesktop,
    isLargeDesktop,
    helpers,
  }
}
