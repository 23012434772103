import { graphql, useStaticQuery } from 'gatsby'
import { isEmptyString } from '~/helpers/stringHelpers'
import { removeParamsFromUrl } from '~/helpers/urlHelpers'
import GatsbyImg from 'gatsby-image'
import React from 'react'

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Image = ({
  relativePath,
  src,
  bgColor,
  containInHeight,
  style = {},
  altText,
  sizes,
  extraSizes,
}) => {
  const data = useStaticQuery(graphql`
    query AllFilesQuery {
      allFile {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 800, quality: 85) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  `)

  const getImageSrc = () => {
    if (src) {
      return src
    } else if (!src && !relativePath) {
      return {}
    }
    const files = data.allFile.edges.map((edge) => edge.node)
    const matchingFile = files.find(
      (file) => file.relativePath === relativePath
    )

    if (matchingFile) {
      return matchingFile.childImageSharp.fluid
    }
    return {}
  }

  const getAdditionalSrcSets = (imageUrl) => {
    return {
      srcSet: extraSizes.map(
        (extraSize) => `${imageUrl}?w=${extraSize}&q=90 ${extraSize}w`
      ),
      srcSetWebp: extraSizes.map(
        (extraSize) => `${imageUrl}?w=${extraSize}&q=90&fm=webp ${extraSize}w`
      ),
    }
  }

  const extraProps = {}
  if (altText && !isEmptyString(altText)) {
    extraProps.alt = altText
  }
  const imageSrc = getImageSrc()
  if (extraSizes && extraSizes.length) {
    const { srcSet, srcSetWebp } = getAdditionalSrcSets(
      removeParamsFromUrl(imageSrc.src)
    )

    imageSrc.srcSet = [...imageSrc.srcSet.split(', '), ...srcSet].join(', ')
    imageSrc.srcSetWebp = [
      ...imageSrc.srcSetWebp.split(', '),
      ...srcSetWebp,
    ].join(', ')
  }

  return (
    <GatsbyImg
      loading="lazy"
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        backgroundColor: bgColor ? bgColor : 'transparent',
      }}
      imgStyle={{ ...style, objectFit: containInHeight ? 'contain' : 'cover' }}
      fluid={{ ...imageSrc, sizes: sizes || imageSrc.sizes }}
      {...extraProps}
    />
  )
}

export default Image
