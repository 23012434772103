import '../queryFragments'
import { Box } from '@hometime-ui/core'
import BulletCollection from './BulletCollection'
import CollapsibleCollection from './CollapsibleCollection'
import FeatureCollection from './FeatureCollection'
import HometimeThemeProvider from '@hometime-ui/theme/HometimeThemeProvider'
import LegalContent from './LegalContent'
import MainLayout from './MainLayout'
import PricingTierCollection from './PricingTierCollection'
import React from 'react'
import ServiceCollection from './ServiceCollection'
import ValueProp from './ValueProp'

export const MODULE_VERTICAL_PADDING = {
  base: 10,
  md: 16,
  lg: 20,
}

// this is made to substitute gatsby-plugin-chakra-ui
// as it uses a later version of chakra-ui
// wrapping in gatsby-browser's wrapperComponent
// makes it so that we lose the theme variables on build
export default function PageWrapper({
  path,
  children,
  modules,
  bottomModules,
  hideBottomCta,
}) {
  return (
    <HometimeThemeProvider>
      <MainLayout hideBottomCta={hideBottomCta}>
        {modules && !!modules.length && renderModules(modules)}
        {children}
        {bottomModules &&
          !!bottomModules.length &&
          renderModules(bottomModules)}
      </MainLayout>
    </HometimeThemeProvider>
  )

  function renderModules(_modules) {
    return <Box className="modules-container">{_modules.map(renderModule)}</Box>
  }

  function renderModule(module, index) {
    const Component = getModuleComponent(module.__typename)
    return (
      <Box key={index} className="module-container">
        {<Component {...module} path={path} />}
      </Box>
    )
  }

  function getModuleComponent(moduleType) {
    switch (moduleType) {
      case 'ContentfulServiceCollection':
        return ServiceCollection
      case 'ContentfulFeatureCollection':
        return FeatureCollection
      case 'ContentfulValueProp':
        return ValueProp
      case 'ContentfulPricingTierCollection':
        return PricingTierCollection
      case 'ContentfulLegalContent':
        return LegalContent
      case 'ContentfulCollapsibleCollection':
        return CollapsibleCollection
      case 'ContentfulBulletCollection':
        return BulletCollection
      default:
        return Box
    }
  }
}
