import { IP_INFO_DB_API_KEY } from '~/config'
import axios from 'axios'

export const extractHtmlFromMarkdownField = (data) => {
  if (!data) {
    return null
  }
  return data.childMarkdownRemark.html
}

export const extractRawStringFromMarkdownField = (data) => {
  if (!data) {
    return null
  }
  return data.childMarkdownRemark.rawMarkdownBody
}

export const fetchIPInfo = () => {
  return axios.get(
    `https://api.ipinfodb.com/v3/ip-city/?key=${IP_INFO_DB_API_KEY}&format=json`
  )
}

export const extractPageDataForSEO = ({
  metaTitle,
  metaImage,
  metaDescription,
  metaKeywords,
}) => {
  return {
    title: metaTitle,
    image: metaImage?.fluid?.src,
    description: extractRawStringFromMarkdownField(metaDescription),
    keywords: metaKeywords,
  }
}
