import { Box, Flex, Grid, Heading, Icon, Text } from '@hometime-ui/core'
import { MdInfoOutline } from 'react-icons/md'
import FeatureListCollection from './FeatureListCollection'
import FormattedHTML from './FormattedHTML'
import PricingTier from './PricingTier'
import React from 'react'
import useScreen from '~/hooks/useScreen'
import WidthWrapper from './WidthWrapper'

export default function PricingTierCollection({ heading, description, items }) {
  const screen = useScreen()

  return (
    <Box pt={{ base: 10, md: 12 }} pb={{ base: 10, md: 20 }}>
      <WidthWrapper>
        <Heading level={screen.isMobile ? 2 : 1} textAlign="center" mb="4">
          {heading}
        </Heading>
        <FormattedHTML
          textAlign="center"
          color="gray.700"
          htmlAst={description.childMarkdownRemark.htmlAst}
        />
        <Grid
          templateColumns={{
            base: '1fr',
            md: 'repeat(2, 1fr)',
            lg: 'repeat(3, 1fr)',
          }}
          mt={{ base: 6, md: 12 }}
          columnGap="8"
        >
          <FeatureListCollection />
          {items.map((item, i) => {
            const isLast = i === items.length - 1
            return <PricingTier key={i} item={item} isLast={isLast} />
          })}
          <Flex
            w="100%"
            bg="gray.300"
            py="3"
            px="4"
            borderRadius="md"
            alignItems="start"
            mt="6"
            gridColumn={{ base: '1/-1', lg: '2/-1' }}
          >
            <Icon as={MdInfoOutline} color="gray.700" h="6" w="6" mr="2" />
            <Text size="s">
              For more information about Billing and Subscriptions, you may read
              our{' '}
              <Text
                as="a"
                href="https://intercom.help/hometime-platform-help/en/collections/3002056-billing-and-subscription"
                fontWeight="bold"
                textDecoration="underline"
                target="_blank"
              >
                FAQs
              </Text>
              .
            </Text>
          </Flex>
        </Grid>
      </WidthWrapper>
    </Box>
  )
}
