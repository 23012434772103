import * as MdIcons from 'react-icons/md'
import { Box, Card, Flex, Heading, Icon, SimpleGrid } from '@hometime-ui/core'
import { MODULE_VERTICAL_PADDING } from './PageWrapper'
import FormattedHTML from './FormattedHTML'
import React from 'react'
import SplashImage from './SplashImage'
import useScreen from '~/hooks/useScreen'
import WidthWrapper from './WidthWrapper'

export default function ServiceCollection({
  splashImagePosition,
  splashImage,
  heading,
  description,
  backgroundColor,
  items,
  path,
}) {
  const { isMobile, isTablet } = useScreen()
  const isSplashImageOnRight = splashImagePosition.toLowerCase() === 'right'
  const slideDirection = splashImage.slideInFrom
  const isHomePage = path === '/'
  return (
    <Box
      bg={isHomePage ? backgroundColor || 'gray.200' : 'white'}
      py={MODULE_VERTICAL_PADDING}
    >
      <WidthWrapper>
        <Flex
          flexDirection={{
            base: 'column',
            lg:
              splashImagePosition.toLowerCase() === 'right'
                ? 'row'
                : 'row-reverse',
          }}
          alignItems={{ lg: 'flex-start' }}
        >
          <Box
            w={{ base: 'full', lg: '47%' }}
            pr={{ lg: isSplashImageOnRight ? '10' : 0 }}
            pl={{ lg: isSplashImageOnRight ? 0 : '10' }}
            className="fadeIn"
          >
            <Heading
              mb={{ base: !description ? 10 : 3 }}
              textAlign="center"
              level={isMobile ? 3 : 2}
              w={{ lg: '80%' }}
              mx="auto"
            >
              {heading}
            </Heading>
            {description && (
              <FormattedHTML
                mb="12"
                textAlign="center"
                color="gray.700"
                htmlAst={description.childMarkdownRemark.htmlAst}
              />
            )}
            <Box>{renderServices()}</Box>
          </Box>
          <Box
            mt={{ base: 10, lg: 0 }}
            h={{ base: '461px', lg: '520px', xl: '670px' }}
            w={{ base: 'full', lg: '53%' }}
            pos="relative"
            className={`${slideDirection ? slideDirection : 'Left'}`}
          >
            <SplashImage
              {...splashImage}
              imageStyle={{
                objectPosition:
                  isMobile || isTablet
                    ? 'center'
                    : splashImagePosition.toLowerCase() === 'right'
                    ? 'right center'
                    : 'left center',
              }}
            />
          </Box>
        </Flex>
      </WidthWrapper>
    </Box>
  )

  function renderServices() {
    return (
      <SimpleGrid columns={{ base: 1, sm: 2 }} spacing={{ base: 4, md: 7 }}>
        {items.map(({ icon, name, shortDescription }, index) => {
          return (
            <Card
              p="6"
              key={index}
              as="a"
              href="/business-services/"
              transition="all .15s"
              boxShadow="0px 1px 2px rgba(94, 101, 115, 0.08), 0px 2px 4px rgba(94, 101, 115, 0.16);"
              _hover={{
                boxShadow:
                  '0px 4px 8px rgba(94, 101, 115, 0.08), 0px 16px 40px rgba(164, 168, 176, 0.24)',
              }}
            >
              <Flex
                borderRadius="full"
                w={{ base: 12, md: 16 }}
                h={{ base: 12, md: 16 }}
                bg="teal.100"
                alignItems="center"
                justifyContent="center"
                mr="2"
              >
                <Icon
                  as={MdIcons[icon]}
                  color="teal.200"
                  w={{ base: 5, md: 7 }}
                  h={{ base: 5, md: 7 }}
                />
              </Flex>
              <Heading level={5} mt="4" mb="2">
                {name}
              </Heading>
              <FormattedHTML
                color="gray.700"
                htmlAst={shortDescription.childMarkdownRemark.htmlAst}
              />
            </Card>
          )
        })}
      </SimpleGrid>
    )
  }
}
