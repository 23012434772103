import { Box, Flex, Heading, Text } from '@hometime-ui/core'
import FormattedHTML from './FormattedHTML'
import React from 'react'
import useScreen from '~/hooks/useScreen'
import WidthWrapper from './WidthWrapper'

export default function LegalContent({ heading, dateUpdated, description }) {
  const screen = useScreen()

  return (
    <Box pt={{ base: 10, md: 12 }} pb={{ base: 10, md: 20 }}>
      <WidthWrapper>
        <Flex w="100%" justifyContent="center">
          <Flex flexDirection="column" alignItems="center" maxW="850px">
            <Heading level={screen.isMobile ? 2 : 1} textAlign="center" mb="4">
              {heading}
            </Heading>
            <Text color="gray.700" textAlign="center" fontSize="sm">
              Last updated on {dateUpdated}
            </Text>
            <FormattedHTML
              className="legal"
              fontSize="sm"
              color="gray.700"
              htmlAst={description.childMarkdownRemark.htmlAst}
              mt={{ base: 6, md: 12 }}
            />
          </Flex>
        </Flex>
      </WidthWrapper>
    </Box>
  )
}
