import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  Heading,
  Icon,
  Text,
} from '@hometime-ui/core'
import { MARKETING_CONTACT_US_LINK } from '~/helpers/constants'
import { MdArrowForward, MdCheck } from 'react-icons/md'
import { MODULE_VERTICAL_PADDING } from './PageWrapper'
import FormattedHTML from './FormattedHTML'
import React from 'react'
import useScreen from '~/hooks/useScreen'
import WidthWrapper from './WidthWrapper'

export default function BulletCollection({
  heading,
  description,
  checklistItems,
  ctaText,
  ctaVariant,
  numRows,
  numColumns,
  backgroundColor,
  path,
}) {
  const { isMobile, isTablet, isLargeDesktop } = useScreen()
  const isHomePage = path === '/'
  return (
    <Box
      bg={isHomePage ? backgroundColor || 'white' : 'white'}
      py={MODULE_VERTICAL_PADDING}
    >
      <WidthWrapper>
        <Flex
          flexDirection={{
            base: 'column',
            lg: 'row',
          }}
          justifyContent="center"
          alignItems="center"
        >
          <Box
            w={{ base: 'full', lg: '100%' }}
            textAlign={{
              base: 'center',
              lg: 'center',
            }}
            className="fadeIn"
          >
            <Box mb="4">
              <Heading level={2} color="gray.900" d="inline-block">
                {heading}
              </Heading>
            </Box>
            {description && (
              <FormattedHTML
                mb="8"
                color="gray.700"
                htmlAst={description.childMarkdownRemark.htmlAst}
              />
            )}
          </Box>
        </Flex>
        {renderCheckListItems()}
        {!!ctaText && renderCTA()}
      </WidthWrapper>
    </Box>
  )

  function renderCTA() {
    if ((ctaVariant ?? 'Button').toLowerCase() === 'button') {
      return (
        <Center mt="60px">
          <Button
            as="a"
            href={MARKETING_CONTACT_US_LINK}
            bg="teal.300"
            color="white"
            fontWeight="700"
            px="6"
            py={{ base: 2, lg: 4 }}
            alignSelf="center"
            h="auto"
            minW="170px"
            fontSize="lg"
          >
            {ctaText}
          </Button>
        </Center>
      )
    }

    return (
      <Flex justifyContent="center" alignItems="center">
        <Text fontWeight="bold" color="teal.200" as="a" role="group">
          {ctaText}
          <Icon
            boxSize="20"
            as={MdArrowForward}
            color="teal.200"
            ml="1"
            transition="all 100ms"
            _groupHover={{ ml: '2' }}
          />
        </Text>
      </Flex>
    )
  }

  function renderCheckListItems() {
    const gridRows = isMobile ? 1 : numRows
    const gridColumns = isMobile || isTablet ? 1 : numColumns
    return (
      <Grid
        mt={4}
        columnGap={isLargeDesktop || isTablet ? 0 : 8}
        rowGap={isMobile || isTablet ? 4 : 0}
        templateRows={`repeat(${gridRows}, 1fr)`}
        templateColumns={`repeat(${gridColumns}, 1fr)`}
      >
        {checklistItems.map((item, i) => {
          return (
            <Flex
              alignItems="center"
              key={i}
              justifyContent={{
                base: 'left',
                lg: 'initial',
              }}
            >
              <Icon as={MdCheck} color="teal.200" w="5" h="5" mr="2" />
              <Text color="gray.700" fontSize="s">
                {item}
              </Text>
            </Flex>
          )
        })}
      </Grid>
    )
  }
}
