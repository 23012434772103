import { Box, Divider, Flex, Icon, Text } from '@hometime-ui/core'
import { MdCheck, MdRemove } from 'react-icons/md'
import React from 'react'
import useScreen from '~/hooks/useScreen'

export default function InclusionsList({ items }) {
  const screen = useScreen()

  return (
    <>
      {items.map((list, index) => {
        const isLast = index === list.length - 1
        const isMdCheck = list === 'MdCheck'
        const isMdCheckGray = list === 'MdCheckGray'
        const isMdRemove = list === 'MdRemove'
        const isText =
          list === 'Unlimited' ||
          list === 'Manual' ||
          list === 'Manual & Automated'

        const [boldText, text] = list.split('~')
        const isBold = boldText.includes('Unlimited')
        const isComingSoon = false

        return (
          <Box key={index}>
            <Box
              as="li"
              d="flex"
              py="4"
              justifyContent={{ base: 'start', lg: 'center' }}
            >
              {screen.isDesktop ? (
                <>
                  {isText && <Text color="gray.700">{list}</Text>}
                  {isMdCheck && (
                    <Icon as={MdCheck} h="6" w="6" color="teal.200" />
                  )}
                  {isMdCheckGray && (
                    <Icon as={MdCheck} h="6" w="6" color="gray.400" />
                  )}
                  {isMdRemove && (
                    <Icon as={MdRemove} h="6" w="6" color="gray.400" />
                  )}
                </>
              ) : (
                <>
                  <Icon as={MdCheck} h="6" w="6" color="teal.200" />
                  <Flex>
                    <Text
                      color="gray.700"
                      fontWeight={isBold ? 'bold' : ''}
                      ml={{ base: '4', lg: '0' }}
                    >
                      {boldText}
                    </Text>
                    <Text color="gray.700" ml="1" whiteSpace="nowrap">
                      {text}
                    </Text>
                  </Flex>
                  {isComingSoon && (
                    <Flex
                      bg="gray.300"
                      py="2px"
                      px="6px"
                      borderRadius="md"
                      ml="3"
                    >
                      <Text fontSize="xs" fontWeight="bold">
                        Coming Soon
                      </Text>
                    </Flex>
                  )}
                </>
              )}
            </Box>
            <Divider borderStyle={!isLast ? 'dashed' : 'none'} />
          </Box>
        )
      })}
    </>
  )
}
