import * as MdIcons from 'react-icons/md'
import { appendTrailingSlashIfNoTrailingSlash } from '~/helpers/stringHelpers'
import {
  Box,
  Caption,
  Divider,
  Flex,
  Heading,
  Icon,
  Text,
  Tooltip,
} from '@hometime-ui/core'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

const featureListQuery = graphql`
  query featureListQuery {
    contentfulFeatureListCollection(heading: { eq: "Features" }) {
      heading
      disclaimerText
      features {
        name
        comingSoon
        icon
        slug
        tooltipDescription {
          tooltipDescription
        }
      }
    }
  }
`

export default function FeatureListCollection({ data }) {
  const { contentfulFeatureListCollection } = useStaticQuery(featureListQuery)
  const { heading, disclaimerText, features } = contentfulFeatureListCollection

  return (
    <Box
      w="100%"
      flexDirection="column"
      pt="8"
      display={{ base: 'none', lg: 'flex' }}
    >
      <Heading level="2" mb="2" mt="39px">
        {heading}
      </Heading>
      <Caption fontSize="xs" color="gray.700" mb="91px">
        {disclaimerText}
      </Caption>
      <Box as="ul" listStyleType="none">
        <Divider />
        {features.map((feature, i) => {
          const isLast = i === features.length - 1
          const featureSlug =
            feature.slug &&
            `/${appendTrailingSlashIfNoTrailingSlash(feature.slug)}`

          return (
            <Box key={i}>
              <Box as="li" d="flex" py="4">
                <Flex
                  borderRadius="full"
                  w="6"
                  h="6"
                  bg="teal.100"
                  alignItems="center"
                  justifyContent="center"
                  mr="2"
                >
                  <Icon
                    as={MdIcons[feature.icon]}
                    color="teal.200"
                    w="3"
                    h="3"
                  />
                </Flex>
                <Box pos="relative" d="flex" alignItems="center" role="group">
                  <Tooltip
                    hasArrow
                    label={feature.tooltipDescription?.tooltipDescription}
                    placement="right"
                    maxW="194px"
                  >
                    <Text
                      as={feature.slug ? 'a' : ''}
                      href={featureSlug}
                      color="gray.700"
                      _hover={feature.slug && { color: 'teal.200' }}
                      fontWeight="bold"
                    >
                      {feature.name}
                    </Text>
                  </Tooltip>
                </Box>
                {feature.comingSoon && (
                  <Flex
                    bg="gray.300"
                    py="2px"
                    px="6px"
                    borderRadius="md"
                    ml="2"
                  >
                    <Text fontSize="xs" fontWeight="bold">
                      Coming Soon
                    </Text>
                  </Flex>
                )}
              </Box>
              <Divider borderStyle={!isLast ? 'dashed' : 'none'} />
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}
