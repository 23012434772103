import { Box, Button, Flex, Heading, Icon, Text } from '@hometime-ui/core'
import { MARKETING_CONTACT_US_LINK } from '~/helpers/constants'
import { MdArrowForward, MdCheck } from 'react-icons/md'
import { MODULE_VERTICAL_PADDING } from './PageWrapper'
import FormattedHTML from './FormattedHTML'
import React from 'react'
import SplashImage from './SplashImage'
import useScreen from '~/hooks/useScreen'
import WidthWrapper from './WidthWrapper'

export default function ValueProp({
  splashImagePosition,
  splashImage,
  heading,
  headingExtension,
  isMainValueProp,
  description,
  ctaText,
  ctaVariant,
  checklistItems = [],
  backgroundColor,
  path,
}) {
  const { isMobile, isTablet } = useScreen()
  const isSplashImageOnRight = splashImagePosition.toLowerCase() === 'right'
  const slideDirection = splashImage.slideInFrom
  const fillContainer = !!splashImage.fillContainer

  // NOTE: there is an override for homepage main value prop styles because it is styled
  // differently compared to the other pages
  const isHomePage = path === '/'

  let fillContainerStyles = {}
  let headingLevel = isMobile ? 3 : 2
  if (isMainValueProp) {
    headingLevel = isMobile ? 2 : 1
  }

  if (fillContainer) {
    fillContainerStyles = {
      display: 'table',
      direction: 'rtl',
    }
  }

  return (
    <Box
      bg={
        isMainValueProp && !isHomePage
          ? 'gray.100'
          : backgroundColor
          ? backgroundColor
          : 'white'
      }
      py={
        isMainValueProp
          ? { base: '10', md: '12', lg: isHomePage ? '48px' : '90px' }
          : MODULE_VERTICAL_PADDING
      }
    >
      <WidthWrapper>
        <Flex
          style={fillContainerStyles}
          flexDirection={{
            base: 'column',
            lg: isSplashImageOnRight ? 'row' : 'row-reverse',
          }}
          alignItems={'center'}
        >
          <Box
            display={fillContainer ? 'table-cell' : ''}
            w={{ base: 'full', lg: '50%' }}
            textAlign={{
              base: isMainValueProp && isHomePage ? 'center' : 'left',
              lg: 'left',
            }}
            pr={{ lg: isSplashImageOnRight ? '10' : 0 }}
            pl={{ lg: isSplashImageOnRight ? 0 : '10' }}
            className="fadeIn"
          >
            <Box mb="4">
              <Heading
                className={path}
                level={headingLevel}
                color={isMainValueProp && isHomePage ? 'teal.300' : 'gray.900'}
                d="inline-block"
              >
                {heading}
              </Heading>
              {headingExtension && (
                <Text
                  fontSize={
                    isMainValueProp
                      ? { base: '2xl', sm: '3xl' }
                      : { base: 'xl', sm: '2xl' }
                  }
                  fontWeight="bold"
                  color={
                    isMainValueProp && isHomePage ? 'teal.300' : 'gray.900'
                  }
                  fontFamily="Public Sans,sans-serif"
                  lineHeight="1.2"
                  d="inline-block"
                  ml={isMainValueProp ? '0' : '2'}
                >
                  {headingExtension}
                </Text>
              )}
            </Box>
            <FormattedHTML
              mb={isMainValueProp ? '8' : '6'}
              color="gray.700"
              htmlAst={description.childMarkdownRemark.htmlAst}
            />
            {!!ctaText && renderCTA()}
            {checklistItems &&
              !!checklistItems.length &&
              renderCheckListItems()}
          </Box>
          <Box
            display={fillContainer ? 'table-cell' : ''}
            mt={{ base: 1, lg: 0 }}
            h={{ base: '304px', md: '350px' }}
            minH={{ lg: isMainValueProp && isHomePage ? '560px' : '349px' }}
            w={{ base: 'full', lg: '50%' }}
            pos="relative"
            className={`${slideDirection ? slideDirection : 'Left'}`}
          >
            <SplashImage
              {...splashImage}
              containInHeight={!fillContainer}
              imageStyle={{
                objectPosition:
                  isMobile || isTablet
                    ? 'center'
                    : isSplashImageOnRight
                    ? 'right center'
                    : 'left center',
              }}
            />
          </Box>
        </Flex>
      </WidthWrapper>
    </Box>
  )

  function renderCTA() {
    if ((ctaVariant ?? 'Button').toLowerCase() === 'button') {
      return (
        <Button
          as="a"
          href={MARKETING_CONTACT_US_LINK}
          bg="teal.300"
          color="white"
          fontWeight="700"
          px="6"
          py={{ base: 2, lg: 4 }}
          alignSelf="center"
          h="auto"
          minW="170px"
          fontSize="lg"
        >
          {ctaText}
        </Button>
      )
    }

    return (
      <Flex alignItems="center">
        <Text
          fontWeight="bold"
          color="teal.200"
          as="a"
          href={MARKETING_CONTACT_US_LINK}
          role="group"
        >
          {ctaText}
          <Icon
            as={MdArrowForward}
            color="teal.200"
            ml="1"
            transition="all 100ms"
            _groupHover={{ ml: '2' }}
          />
        </Text>
      </Flex>
    )
  }

  function renderCheckListItems() {
    return (
      <Box mt="4">
        {checklistItems.map((item, i) => {
          return (
            <Flex
              alignItems="center"
              key={i}
              mb="2"
              justifyContent={{
                base: isMainValueProp && isHomePage ? 'center' : 'initial',
                lg: 'initial',
              }}
            >
              <Icon as={MdCheck} color="teal.200" w="4" h="4" mr="1" />
              <Text color="gray.700" fontSize="xs">
                {item}
              </Text>
            </Flex>
          )
        })}
      </Box>
    )
  }
}
