import Image from './Image'
import React from 'react'

export default function SplashImage({
  image,
  imageStyle = {},
  containInHeight = true,
}) {
  return (
    <Image
      src={image.fluid}
      containInHeight={containInHeight}
      extraSizes={[500, 800, 1000, 1200, 1400]}
      style={imageStyle}
    />
  )
}
