import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
} from '@hometime-ui/core'
import { MODULE_VERTICAL_PADDING } from './PageWrapper'
import FormattedHTML from './FormattedHTML'
import React from 'react'
import SplashImage from './SplashImage'
import useScreen from '~/hooks/useScreen'
import WidthWrapper from './WidthWrapper'

export default function CollapsibleCollection({
  heading,
  solutions,
  description,
  splashImage,
  splashImagePosition,
  backgroundColor,
  path,
}) {
  const { isMobile, isTablet } = useScreen()
  const isSplashImageOnRight = splashImagePosition
  const slideDirection = splashImage.slideInFrom || 'right'
  let headingLevel = isMobile ? 3 : 2

  // NOTE: there is an override for homepage main value prop styles because it is styled
  // differently compared to the other pages
  const isHomePage = path === '/'

  return (
    <Box
      bg={isHomePage ? backgroundColor || 'gray.100' : 'white'}
      py={MODULE_VERTICAL_PADDING}
    >
      <WidthWrapper>
        <Flex
          flexDirection={{
            base: 'column',
            lg: isSplashImageOnRight ? 'row' : 'row-reverse',
          }}
          alignItems={'center'}
        >
          <Box
            w={{ base: 'full', lg: '50%' }}
            textAlign={{
              base: isHomePage ? 'center' : 'left',
              lg: 'left',
            }}
            pr={{ lg: isSplashImageOnRight ? '10' : 0 }}
            pl={{ lg: isSplashImageOnRight ? 0 : '10' }}
            className="fadeIn"
          >
            <Box mb="4">
              <Heading level={headingLevel} color="gray.900" d="inline-block">
                {heading}
              </Heading>
            </Box>
            {description && (
              <FormattedHTML
                mb="8"
                color="gray.700"
                htmlAst={description.childMarkdownRemark.htmlAst}
              />
            )}
            {renderAccordion()}
          </Box>

          <Box
            mt={{ base: 1, lg: 0 }}
            h={{ base: '304px', md: '350px' }}
            minH={{ lg: '560px' }}
            w={{ base: 'full', lg: '50%' }}
            pos="relative"
            className={`${slideDirection ? slideDirection : 'Left'}`}
          >
            <SplashImage
              {...splashImage}
              imageStyle={{
                objectPosition:
                  isMobile || isTablet
                    ? 'center'
                    : isSplashImageOnRight
                    ? 'right center'
                    : 'left center',
              }}
            />
          </Box>
        </Flex>
      </WidthWrapper>
    </Box>
  )

  function renderAccordion() {
    return (
      <Accordion allowToggle>
        {solutions.map((solution, i) => {
          return (
            <AccordionItem key={i} mr="0" ml="0" mb="4">
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  {solution.title}
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel p="5">
                <FormattedHTML
                  color="gray.700"
                  htmlAst={solution.description.childMarkdownRemark.htmlAst}
                />
              </AccordionPanel>
            </AccordionItem>
          )
        })}
      </Accordion>
    )
  }
}
