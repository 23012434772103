import { appendTrailingSlashIfNoTrailingSlash } from '~/helpers/stringHelpers'
import { Box, Divider, Icon, SimpleGrid } from '@hometime-ui/core'
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from 'react-icons/fa'
import { Flex, Text } from '@hometime-ui/core'
import { graphql, useStaticQuery } from 'gatsby'
import { MODULE_VERTICAL_PADDING } from './PageWrapper'
import Logo from '@hometime-ui/logo'
import React from 'react'
import useNavFeatures from '~/hooks/useNavFeatures'
import WidthWrapper from './WidthWrapper'

const SOCIAL_MEDIA_ENUMS = Object.freeze({
  FACEBOOK: 'facebookProfile',
  TWITTER: 'twitterProfile',
  LINKEDIN: 'linkedInProfile',
  INSTAGRAM: 'instagramProfile',
})

const SOCIAL_MEDIA_LINKS = [
  {
    datakey: SOCIAL_MEDIA_ENUMS.FACEBOOK,
    name: 'facebook',
    icon: FaFacebookF,
  },
  {
    datakey: SOCIAL_MEDIA_ENUMS.INSTAGRAM,
    name: 'instagram',
    icon: FaInstagram,
  },
  {
    datakey: SOCIAL_MEDIA_ENUMS.TWITTER,
    name: 'twitter',
    icon: FaTwitter,
  },
  {
    datakey: SOCIAL_MEDIA_ENUMS.LINKEDIN,
    name: 'linkedin',
    icon: FaLinkedinIn,
  },
]

const FOOTER_LINKS = [
  {
    index: 0,
    name: 'Terms and Conditions',
    url: '/terms/',
  },
  {
    index: 1,
    name: 'Privacy Policy',
    url: '/privacy-policy/',
  },
  {
    index: 2,
    name: 'Blog',
    url: '/blog/',
  },
]

export default function Footer({ children }) {
  const { data } = useNavFeatures()
  const {
    contentfulWebsiteInfo,
    allContentfulService,
  } = useStaticQuery(graphql`
    query FooterServicesQuery {
      contentfulWebsiteInfo {
        facebookProfile
        twitterProfile
        linkedInProfile
        instagramProfile
      }
      allContentfulService {
        nodes {
          name
        }
      }
    }
  `)

  return (
    <Box
      as="footer"
      py={MODULE_VERTICAL_PADDING}
      pb={{ md: 10, lg: 10 }}
      bg="gray.100"
      boxShadow="0px -1px 0px rgba(94, 101, 115, 0.08)"
      pos="relative"
    >
      <WidthWrapper>
        <Flex
          justifyContent="space-between"
          pb="6"
          flexDirection={{ base: 'column', md: 'row' }}
        >
          <Box
            maxW={{ base: 'none', md: '40%', lg: '35%' }}
            mb={{ base: 15, md: 0 }}
          >
            <Text as="a" d="inline-block" href="/">
              <Logo color="teal.200" h="6" w="132px" />
            </Text>
            <Text mt="4" color="gray.700">
              A robust property management system that’s intuitive and easy to
              use. Spend less time on admin and more time growing your business.
            </Text>
          </Box>
          <Flex justifyContent={{ base: 'space-between', sm: 'initial' }}>
            {renderFeatures()}
            {renderServices()}
          </Flex>
        </Flex>
        <Divider mb="6" />
        <Flex
          justifyContent={{ base: 'initial', md: 'space-between' }}
          flexDirection={{ base: 'column', md: 'row' }}
          alignItems="center"
        >
          <Flex
            flexDirection={{ base: 'column', lg: 'row' }}
            alignItems={{ lg: 'center' }}
          >
            <Text
              color="gray.700"
              fontSize="sm"
              textAlign={{ base: 'center', md: 'left' }}
              mr={{ md: 4 }}
            >
              © {new Date().getFullYear()} Hometime. All rights reserved.{' '}
            </Text>
            {renderFooterLinks()}
          </Flex>

          {renderSocialMediaLinks()}
        </Flex>
      </WidthWrapper>
    </Box>
  )

  function renderServices() {
    return (
      <Box ml="10">
        <Text mb="4" color="gray.800" fontSize="xs" fontWeight="bold">
          SERVICES
        </Text>
        {allContentfulService.nodes.map((service) => {
          return (
            <Text
              d="block"
              fontSize="sm"
              mb="1"
              key={service.name}
              as="a"
              href="/business-services/"
              color="gray.700"
            >
              {service.name}
            </Text>
          )
        })}
      </Box>
    )
  }

  function renderFeatures() {
    return (
      <Box>
        <Text mb="4" color="gray.800" fontSize="xs" fontWeight="bold">
          FEATURES
        </Text>
        {data.items.map((feature) => {
          return (
            <Text
              d="block"
              fontSize="sm"
              mb="1"
              key={feature.name}
              as="a"
              href={`/${appendTrailingSlashIfNoTrailingSlash(feature.slug)}`}
              color="gray.700"
              transition="all .15s"
              _hover={{ color: 'teal.300' }}
            >
              {feature.name}
            </Text>
          )
        })}
      </Box>
    )
  }

  function renderFooterLinks() {
    return (
      <Box as="ul" d="flex" alignItems="center">
        {FOOTER_LINKS.map(({ name, url, index }) => {
          const isFirst = index === 0

          return (
            <Box
              as="li"
              listStyleType={{ base: 'none', md: isFirst ? 'none' : 'disc' }}
              mr={{ base: 4, md: 8 }}
              key={name}
            >
              <Text as="a" href={url} color="gray.700" fontSize="sm">
                {name}
              </Text>
            </Box>
          )
        })}
      </Box>
    )
  }

  function renderSocialMediaLinks() {
    return (
      <SimpleGrid
        spacingX={{ base: 8, md: 4, lg: 8 }}
        columns={SOCIAL_MEDIA_LINKS.length}
        mt={{ base: '6', md: 0 }}
      >
        {SOCIAL_MEDIA_LINKS.map(({ icon, name, datakey }) => {
          const hometimeSocialMediaUrl = contentfulWebsiteInfo[datakey]
          return (
            <Text
              as="a"
              key={name}
              href={hometimeSocialMediaUrl}
              color="gray.700"
              _hover={{ color: 'teal.300' }}
              target="_blank"
            >
              <Icon
                as={icon}
                w="5"
                h="5"
                _hover={{ color: 'teal.300' }}
                transition="all .15s"
              />
            </Text>
          )
        })}
      </SimpleGrid>
    )
  }
}
