import * as MdIcons from 'react-icons/md'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Icon,
  Text,
} from '@hometime-ui/core'
import { appendTrailingSlashIfNoTrailingSlash } from '~/helpers/stringHelpers'
import { NAVLINKS } from './Header'
import CallToAction from './CallToAction'
import Logo from '@hometime-ui/logo'
import React from 'react'
import useNavFeatures from '~/hooks/useNavFeatures'

export default function MobileNavDrawer({ isOpen, onClose }) {
  const { data } = useNavFeatures()
  return (
    <Drawer placement="right" isOpen={isOpen} onClose={onClose} size="xs">
      <DrawerOverlay>
        <DrawerContent>
          {renderHeader()}
          <DrawerBody p="0" flex="1 1 0%" overflowY="auto" overflowX="hidden">
            {renderBody()}
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  )

  function renderHeader() {
    return (
      <>
        <DrawerCloseButton mt="1" />
        <DrawerHeader p="4">
          <Text as="a" d="inline-block" href="/">
            <Logo color="teal.200" h="5" w="25" />
          </Text>
        </DrawerHeader>
      </>
    )
  }

  function renderBody() {
    return (
      <>
        <Box p="4" pt="2" pb={0}>
          {NAVLINKS.map((navLink) => {
            const isFeatures = navLink.text === 'Features'
            return (
              <Flex
                as={isFeatures ? 'span' : 'a'}
                href={navLink.href}
                py="4"
                key={navLink.text}
                borderBottom="1px dashed"
                borderColor="gray.400"
              >
                {isFeatures ? (
                  renderFeatureAccordion()
                ) : (
                  <Text fontWeight="bold" fontSize="sm" color="gray.800">
                    {navLink.text}
                  </Text>
                )}
              </Flex>
            )
          })}
        </Box>
        <Box mt={4}>
          <CallToAction />
        </Box>
      </>
    )
  }

  function renderFeatureAccordion() {
    return (
      <Accordion w="full" allowToggle>
        <AccordionItem
          borderRadius="0"
          p="0"
          boxShadow="none"
          allowToggle
          allowMultiple
          w="full"
          border="none"
          m="0"
        >
          <AccordionButton
            boxShadow="none"
            w="full"
            p="0"
            as={Flex}
            cursor="pointer"
            justifyContent="space-between"
            borderColor="white"
          >
            <Text fontWeight="bold" fontSize="sm" color="gray.800">
              Features
            </Text>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel border="none" p="0" mt="4">
            {data.items.map(({ icon, name, slug }, index) => {
              const isLast = index === data.items.length - 1
              return (
                <Flex
                  as="a"
                  href={`/${appendTrailingSlashIfNoTrailingSlash(slug)}`}
                  key={name}
                  alignItems="center"
                  borderRadius="lg"
                  p="2"
                  mb={isLast ? 0 : 2}
                  transition="all .15s"
                  _hover={{ textDecoration: 'underline', bg: 'gray.200' }}
                >
                  <Flex
                    borderRadius="full"
                    w="8"
                    h="8"
                    bg="teal.100"
                    alignItems="center"
                    justifyContent="center"
                    mr="2"
                  >
                    <Icon as={MdIcons[icon]} color="teal.200" w="3" h="3" />
                  </Flex>
                  <Box flex="1">
                    <Text color="teal.300" fontSize="sm" fontWeight="700">
                      {name}
                    </Text>
                  </Box>
                </Flex>
              )
            })}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    )
  }
}
