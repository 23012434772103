import { Box } from '@hometime-ui/core'
import CallToAction from './CallToAction'
import Footer from './Footer'
import GlobalStyles from '~/theme/GlobalStyles'
import Header from './Header'
import React from 'react'
import useScreen from '~/hooks/useScreen'

export default function MainLayout({ children, hideBottomCta }) {
  const { isDesktop } = useScreen()

  return (
    <>
      <GlobalStyles />
      <Header />
      <Box
        className="main-content-container"
        pt={isDesktop ? '72px' : '60px'}
        bg="gray.100"
      >
        {children}
      </Box>
      {!hideBottomCta && <CallToAction />}
      <Footer />
    </>
  )
}
