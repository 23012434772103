import { Box } from '@hometime-ui/core'
import React from 'react'

export const widthWrapperPX = { base: 4, md: 10, lg: 8, xl: 2 }

export default function WidthWrapper({ children, ...props }) {
  return (
    <Box
      maxW="1290px"
      mx="auto"
      px={widthWrapperPX}
      {...props}
      className="width-wrapper"
    >
      {children}
    </Box>
  )
}
