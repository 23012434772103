import { Box, Flex, Icon, Text } from '@hometime-ui/core'
import { MdCheck } from 'react-icons/md'
import React from 'react'
import rehypeReact from 'rehype-react'
import styled from '@emotion/styled'

const StyledFormattedHTML = styled(Box)`
  p,
  ul,
  ol {
    direction: ltr;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  ul,
  ol {
    list-style-type: none;
    padding: 0;
  }

  img {
    margin: 0 auto;
  }
  a {
    text-decoration: underline;
  }
  h5 {
    font-size: 16px;
    font-weight: bold;
  }

  iframe {
    margin: 0 auto 32px auto;
    width: 100%;
    min-height: 400px;
  }

  &.legal {
    ul {
      margin-top: -20px;
      padding-left: 20px;
      list-style-type: disc;
      li {
        display: list-item;
        padding-left: 0;
        margin-bottom: 0px;

        p {
          word-break: break-word;
        }
      }
    }
    .check-icon {
      display: none;
    }

    hr {
      opacity: 0;
    }
  }

  &.blog {
    a {
      text-decoration: none;
      color: #39b0a4;
    }

    h2,
    h3 {
      font-weight: bold;
      color: #242427;
      margin-bottom: 16px;
      font-size: 24px;
      line-height: 29px;
    }

    p {
      margin-bottom: 32px;
    }

    ul {
      margin-bottom: 32px;
      li p {
        margin-bottom: 0px;
      }
    }

    img {
      border-radius: 8px;
    }
  }
`

const FormattedUl = ({ children }) => {
  const items = children.filter((item) => {
    return typeof item !== 'string'
  })

  return (
    <ul>
      {items.map((item, index) => {
        const isLast = index === items.length - 1
        return (
          <Flex as="li" key={index} mb={isLast ? 0 : 3} pos="relative" pl="8">
            <Icon
              as={MdCheck}
              color="teal.200"
              w="5"
              h="5"
              mr="3"
              mt="2px"
              pos="absolute"
              left="0"
              top="0"
              className="check-icon"
            />
            <Text {...item.props} as="article"></Text>
          </Flex>
        )
      })}
    </ul>
  )
}

const FormattedOl = ({ children }) => {
  const items = children.filter((item) => {
    return typeof item !== 'string'
  })

  return (
    <ol>
      {items.map((item, index) => {
        const isLast = index === items.length - 1
        return (
          <Flex as="li" key={index} mb={isLast ? 0 : 3} pos="relative" pl="8">
            <Box
              as="span"
              d="inline-block"
              h="6"
              w="6"
              borderRadius="full"
              bg="teal.100"
              textAlign="center"
              color="teal.200"
              fontSize="xs"
              lineHeight="6"
              pos="absolute"
              left="0"
              top="0"
            >
              {index + 1}
            </Box>
            <Text {...item.props} as="article"></Text>
          </Flex>
        )
      })}
    </ol>
  )
}

FormattedUl

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: { ul: FormattedUl, ol: FormattedOl },
}).Compiler

export default function FormattedHTML({ htmlAst, className, ...props }) {
  if (!htmlAst) {
    return null
  }

  return (
    <Box {...props}>
      <StyledFormattedHTML className={className}>
        {renderAst(htmlAst)}
      </StyledFormattedHTML>
    </Box>
  )
}
