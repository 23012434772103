export const removeParamsFromUrl = (url) => {
  return url.split('?')[0]
}

export const addToParamsIfExist = ({ params, key, value, formatValue }) => {
  const prefixSymbol = params.indexOf('?') > -1 ? '&' : '?'

  if (!value) {
    return params
  }
  return `${params}${prefixSymbol}${key}=${
    formatValue ? formatValue(value) : value
  }`
}
