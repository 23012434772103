export const httpToHttps = (url) => {
  let newUrl = `${url}`
  if (newUrl.indexOf('//') === 0) {
    return `https:${newUrl}`
  } else if (newUrl.indexOf('http://') === 0) {
    return newUrl.replace('http://', 'https://')
  }
  return newUrl
}

export const isEmptyString = (string) => {
  return !string.trim().length
}

export const appendTrailingSlashIfNoTrailingSlash = (string) => {
  if (string.charAt(string.length - 1) !== '/') {
    return `${string}/`
  }
  return string
}
