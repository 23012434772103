import {
  appendTrailingSlashIfNoTrailingSlash,
  httpToHttps,
} from '~/helpers/stringHelpers'
import { graphql, useStaticQuery } from 'gatsby'
import Helmet from 'react-helmet'
import React from 'react'

export default function SEO({
  description,
  keywords,
  lang = 'en',
  title,
  image,
  pathname = '',
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
            description
          }
        }
      }
    `
  )

  const metaKeywords = `${keywords || ''}`
  const websiteName = site.siteMetadata.title
  const metaTitle = `${title || websiteName}`
  const metaDescription = `${description || site.siteMetadata.description}`
  const canonicalUrl = appendTrailingSlashIfNoTrailingSlash(
    `${site.siteMetadata.siteUrl}${pathname}`
  )
  const metaImage = image ? httpToHttps(image) : null
  const facebookVerification = 'ndbf9bnjuwim9q5gpebtbrycjk6wjj'

  return (
    <Helmet
      title={metaTitle}
      link={[
        { rel: 'canonical', href: canonicalUrl },
        { rel: 'alternate', href: canonicalUrl, hreflang: lang },
      ]}
    >
      <meta http-equiv="Cache-control" content="public, max-age=0, no-cache" />
      <meta property="og:site_name" content={websiteName} />
      <meta property="og:type" content="website" />
      <meta name="keywords" content={metaKeywords} />
      <meta name="description" content={metaDescription} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:title" content={metaTitle} />
      {metaImage && (
        <meta property="og:image" content={httpToHttps(metaImage)} />
      )}
      <meta property="og:url" content={canonicalUrl} />
      <meta
        name="facebook-domain-verification"
        content={facebookVerification}
      />
      <meta name="twitter:card" content="summary" />
      <meta name="application-name" content={websiteName} />
      <meta name="msapplication-tooltip" content={`${websiteName} Website`} />
      <meta name="msapplication-starturl" content="/" />
      <meta name="msapplication-navbutton-color" content="#ffffff" />
      <meta name="msapplication-task" content="name=;action-uri=;icon-uri=" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-title" content={websiteName} />
      <meta
        name="apple-mobile-web-app-status-bar-style"
        content="black-translucent"
      />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=5"
      />
      <meta name="msapplication-TileColor" content="#322632" />
    </Helmet>
  )
}
