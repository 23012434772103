import { Button, Flex, Heading, Icon, Text } from '@hometime-ui/core'
import { graphql, useStaticQuery } from 'gatsby'
import { MARKETING_CONTACT_US_LINK } from '~/helpers/constants'
import { MdCheck } from 'react-icons/md'
import { MODULE_VERTICAL_PADDING } from './PageWrapper'
import React from 'react'
import useScreen from '~/hooks/useScreen'
import WidthWrapper from './WidthWrapper'

const callToActionPageQuery = graphql`
  query callToActionPageQuery {
    contentfulCallToAction(contentfulid: { eq: "Call To Action" }) {
      ...callToAction
    }
  }
`
export default function CallToAction() {
  const screen = useScreen()

  const { contentfulCallToAction } = useStaticQuery(callToActionPageQuery)
  const { heading, buttonText } = contentfulCallToAction

  return (
    <WidthWrapper pb={MODULE_VERTICAL_PADDING}>
      <Flex
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        bg="teal.300"
        borderRadius="md"
        mx="auto"
        py={{ base: 12, lg: 24 }}
        px="4"
        className="Top"
      >
        <Heading
          level={screen.isMobile ? 3 : 2}
          textAlign="center"
          mb="8"
          color="white"
        >
          {heading}
        </Heading>
        <Button
          as="a"
          href={MARKETING_CONTACT_US_LINK}
          bg="white"
          color="teal.300"
          height="auto"
          px="10"
          py="4"
        >
          {buttonText}
        </Button>
        <Flex
          flexDirection={{ base: 'column', sm: 'row' }}
          alignItems="center"
          justifyContent="center"
          mt="4"
        >
          {contentfulCallToAction.checklist.map((item, i) => {
            const isLast = i === contentfulCallToAction.checklist.length - 1
            return (
              <Flex key={i}>
                <Icon
                  color="teal.200"
                  h="4"
                  w="4"
                  mr="1"
                  ml={isLast ? '1' : '0'}
                  as={MdCheck}
                />
                <Text fontSize="xs" color="white">
                  {item}
                </Text>
              </Flex>
            )
          })}
        </Flex>
      </Flex>
    </WidthWrapper>
  )
}
