import {
  Box,
  Button,
  Caption,
  Divider,
  Flex,
  Heading,
  Text,
} from '@hometime-ui/core'
import { MARKETING_CONTACT_US_LINK } from '~/helpers/constants'
import InclusionsList from './Pricing/InclusionsList'
import React, { useEffect, useState } from 'react'
import useScreen from '~/hooks/useScreen'

export default function PricingTier({ item, isLast }) {
  const screen = useScreen()

  const [inclusions, setInclusions] = useState([])

  useEffect(() => {
    if (screen.isDesktop) {
      setInclusions(item.desktopInclusions)
    } else {
      setInclusions(item.inclusions)
    }
  }, [screen])

  return (
    <Flex
      w="100%"
      bg="white"
      borderColor="white"
      borderWidth="3px"
      shadow={{ base: 'md', md: '2xl' }}
      borderRadius="md"
      alignItems="center"
      flexDirection="column"
      p={{ base: 4, md: 8 }}
      mt={isLast && { base: 6, md: 0 }}
      _hover={{
        borderColor: 'teal.200',
      }}
    >
      <Heading level="3" mb="2" color="teal.200">
        {item.name}
      </Heading>
      <Flex alignItems="flex-end" mb="2">
        {isNaN(item.price) ? (
          <Heading level="2" mr="1">
            Custom
          </Heading>
        ) : (
          <>
            <Heading level="2" mr="1">
              ${item.price}
            </Heading>
            <Text fontWeight="bold">USD/property</Text>
          </>
        )}
      </Flex>

      <Caption fontSize="xs" color="gray.700">
        {item.disclaimerText}
      </Caption>
      <Button
        height="auto"
        as="a"
        href={MARKETING_CONTACT_US_LINK}
        px="6"
        py="3"
        mt="2"
        mb="8"
        borderWidth="1px"
        fontSize="lg"
        bg="teal.300"
        color="white"
        borderColor="teal.300"
        _hover={{
          bg: 'teal.200',
          borderColor: 'teal.200',
        }}
      >
        {item.buttonText}
      </Button>
      <Box as="ul" listStyleType="none" w="100%">
        <Divider />
        <InclusionsList items={inclusions} />
      </Box>
    </Flex>
  )
}
