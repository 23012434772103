import { css, Global } from '@emotion/core'
import React from 'react'

const GlobalStyles = () => {
  return (
    <Global
      styles={css`
        &.fadeIn {
          opacity: 0;
          animation: fadeIn 0.5s ease-in-out forwards;
        }
        &.Right {
          opacity: 0;
          animation: slideFromRight 0.5s ease-in-out forwards;
        }
        &.Left {
          opacity: 0;
          animation: slideFromLeft 0.5s ease-in-out forwards;
        }
        &.Top {
          opacity: 0;
          animation: slideFromTop 0.5s ease-in-out forwards;
        }
        &.Bottom {
          opacity: 0;
          animation: slideFromBottom 0.5s ease-in-out forwards;
        }

        @keyframes slideFromRight {
          0% {
            transform: translateX(20%);
            opacity: 0;
          }
          100% {
            transform: translateX(0);
            opacity: 1;
          }
        }
        @keyframes slideFromLeft {
          0% {
            transform: translateX(-20%);
            opacity: 0;
          }
          100% {
            transform: translateX(0);
            opacity: 1;
          }
        }
        @keyframes slideFromTop {
          0% {
            transform: translateY(20%);
            opacity: 0;
          }
          100% {
            transform: translateY(0);
            opacity: 1;
          }
        }
        @keyframes slideFromBottom {
          0% {
            transform: translateY(-20%);
            opacity: 0;
          }
          100% {
            transform: translateY(0);
            opacity: 1;
          }
        }
        @keyframes fadeIn {
          0% {
            opacity: 0;
          }

          100% {
            opacity: 1;
          }
        }
      `}
    />
  )
}

export default GlobalStyles
